<template>
  <a-card>
    <div class="top_info">
      <div class='alert_box'>
        <a-alert
            style='width: 280px;'
            message='操作步骤'
            description='1.可通过在操作栏输入顺序号，改变经销商数据导出的排序。
            2.顺序号上限为经销商总数'
            type='warning'
            closable
        />
      </div>
    </div>
    <a-form-model
        v-bind='layout'
        ref='search_form'
        :model='search_form'
    >
      <a-row>
        <a-col :span='7'>
          <a-form-model-item label='经销商名称'>
            <a-input
                v-model='search_form.dealer_name'
                placeholder='请输入'
            />
          </a-form-model-item>
        </a-col>
        <a-col :span='7'>
          <a-form-model-item label='经销商ID'>
            <a-input
                v-model='search_form.dealer_id'
                placeholder='请输入'
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col class='flex-end'>
          <a-button @click="resetSearch" class="mr-20">重置</a-button>
          <a-button type='primary' @click="bindSearch">搜索</a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="dealerData"><span>经销商数量： {{ data_list.length || 0 }}</span></div>
    <a-table
        :columns="columns"
        :data-source="data_list"
        :pagination="false"
        :rowKey="(record, index) => index"
        :loading="loading"
        class="mt-40p"
        :key='table_key'
    >
      <span slot='customTitle'>
        操作
          <a-tooltip>
            <template slot='title'>
              请填写顺序号
            </template>
            <a-icon type='info-circle' style='margin-left: 3px; cursor: pointer'/>
          </a-tooltip>
        </span>
      <div slot='dealer_count' slot-scope='text, record'>
        <div class="row-box box-1">
          <div><span>经</span><span>销</span><span>商</span><span>名</span><span>称：</span></div>
          <div>{{ record.dealer_name || '--' }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>经</span><span>销</span><span>商</span><span>I</span><span>D：</span></div>
          <div>{{ record.dealer_id || '--' }}</div>
        </div>
      </div>
      <div slot="authorInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>昵</span><span>称：</span></div>
          <div>
            <div>{{ record.nickname || '--' }}</div>
          </div>
        </div>
        <div class="row-box box-1">
          <div><span>平</span><span>台：</span></div>
          <div>
            <span v-if="record.platform == 1">抖音</span>
            <span v-else-if="record.platform == 2">快手</span>
            <span v-else-if="record.platform == 3">懂车帝</span>
            <span v-else>--</span>
          </div>
        </div>
        <div class="row-box box-1">
          <div><span>平</span><span>台</span><span>账</span><span>号：</span></div>
          <div>{{ record.code || '--' }}</div>
        </div>
      </div>
      <div slot="operation" slot-scope="text, record,index">
        <a-input
            ref="numberIinput"
            class="numberIinput"
            v-model="record.sort_number"
            placeholder='请输入顺序号'
            style="width: 100%"
            @keyup.enter='putSerialNumber(record,index)'
            @blur='putSerialNumber(record,index)'
        />
      </div>
    </a-table>
    <div class="submitNumber">
      <a-button @click="sortListByNumber">刷新排序</a-button>
      &nbsp; &nbsp;
      <a-button type="primary" @click="submitNumber">保存</a-button>
    </div>
  </a-card>
</template>

<script>
import _api from '@/api/configurationTemplate';
import { Tool } from '@/utils';

export default {
  name: "configurationTemplate",
  data () {
    return {
      search_form: {},
      layout: {
        labelCol: {span: 8},
        wrapperCol: {span: 16}
      },
      dealerData: {},
      columns: [
        {
          dataIndex: 'sort',
          width: 80,
          title: '原排序'
        },
        {
          dataIndex: 'dealer_count',
          scopedSlots: {customRender: 'dealer_count'},
          width: 150,
          title: '经销商信息'
        },
        {
          dataIndex: 'area_name',
          width: 150,
          title: '区域'
        },
        {
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          width: 100,
          slots: {title: 'customTitle'}
        }
      ],
      data_list: [],
      loading: false,
      activity_name: this.$route.query.activity_name || '',
      activity_id: this.$route.query.activity_id || '',
      principal_id: this.$route.query.principal_id || '',
      sort_number: null,
      table_key: new Date().getTime()
    };
  },
  created () {
    this.getTemplateList();
  },
  methods: {
    putSerialNumber (row, index) {
      if (row.sort_number) {
        let reg = /^([1-9][0-9]*)$/;
        if (reg.test(row.sort_number)) {
          if (row.sort_number > this.data_list.length) {
            this.$error({
              title: '序号已超出经销商总数，请重新输入'
            });
            this.sort_number = null;
            return false;
          }
          // let arr = _.cloneDeep(this.data_list);
          // const arr_list = Tool.putSort(arr, index, parseInt(row.sort_number));
          // this.data_list[index].sort_number = null;
          // this.data_list = arr_list.map(item => {
          //   item.sort_number = null;
          //   return item;
          // });
          // this.table_key = new Date().getTime();
        } else {
          this.$message.error('请输入大于等于0的正数');
        }
      }
    },// 顺序修改
    submitNumber () {

      // 校验
      let valiteList = _.uniqBy(this.data_list, 'sort_number')

      if(valiteList.length<this.data_list.length) {
        this.$message.error("部分经销商没有输入顺序号或顺序号重复，请检查。")
        return
      }
      let list = [];
      this.data_list.map(item => {
        if (item.sort_number) {
          list.push({
            dealer_id: item.dealer_id,
            sort: +item.sort_number
          });
        }
      });
      const params = {
        activity_id: this.activity_id,
        // activity_id: '31',
        activity_dealer_sorts: list
      };
      // console.log(params)
      _api.updateTemplateList(params).then(res => {
        if (res.code == 0) {
          this.$message.success('导出模板配置成功');
          this.$router.push({
            path: '/training/account',
            query: {
              activity_name: this.activity_name,
              activity_id: this.activity_id,
              principal_id: this.principal_id
            }
          });
        } else {
          this.$message.error('导出模板配置失败');
        }
      });
    },
    bindSearch () {
      this.getTemplateList();
    }, // 搜索
    resetSearch () {
      this.search_form = {};
      this.getTemplateList();
    }, // 重置
    getTemplateList () {
      this.loading = true;
      const params = {
        activity_id: this.activity_id,
        principal_id: this.principal_id,
        dealer_id: this.search_form.dealer_id,
        dealer_name: this.search_form.dealer_name
      };
      _api.getTemplateList(params).then(res => {
        this.loading = false;
        if (res.code == 0) {
          let list = res.data || [];
          list.map((item) => {
            item.sort_number = null;
          });
          this.data_list = list;
        } else {
          console.error('获取列表失败');
        }
      });
    }, // 获取列表

    sortListByNumber() {
      let list = _.cloneDeep(this.data_list)
      list = _.sortBy(this.data_list, 'sort_number')
      this.data_list = list
    }
  }
};
</script>

<style scoped lang="less">
.top_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  position: relative;

  .alert_box {
    position: absolute;
    top: -40px;
    right: -10px;
  }
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 40px;
}

.dealerData {
  margin: 20px 15px;

  .span-text {
    padding-left: 20px;
  }
}

.mr-20 {
  margin-right: 20px;
}


.row-box {
  display: flex;
  margin-bottom: 5px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: bold;

    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  & > div:last-child {
    flex: 1;
    min-width: 50px;
  }
}

.box-1 {
  & > div:first-child {
    width: 84px;
  }
}

.submitNumber {
  display: flex;
  justify-content: right;
  padding-top: 30px;
}

.numberIinput /deep/ .ant-input-number-handler-wrap {
  -webkit-appearance: none !important;
  margin: 0;
  display: none;
}
</style>

